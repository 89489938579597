import { createSlice } from '@reduxjs/toolkit'


const initialState = { 
  isProfileEditing: false,
  updatedFileList: [],
  updatedImage: [],
  isUploadLoading: false,
}

const employeeSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsProfileEditing(state, action) {
      state.isProfileEditing = action.payload
    },
    setUpdatedFileList(state, action) {
      state.updatedFileList = action.payload
    },
    setUpdatedImage(state, action) {
      state.updatedImage = action.payload
    },
    setIsUploadLoading(state, action) {
      state.isUploadLoading = action.payload
    },
  },
});

const allActions = employeeSlice.actions

export const employeeActions = {
  // all reducer
  ...allActions,
  // all actions (might override reducer if needed)
  
}

export default employeeSlice.reducer;