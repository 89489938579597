import { useEffect, useRef, useState } from "react"
import BoSidebar from "./_components/bo-sidebar"
import BoHeader from "./_components/bo-header"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { appActions } from "@/store/slices/app"
import { allRoutes } from "@/router"
import { useUtilityApi } from "@/api/utility"
import { useAuthApi } from "@/api/auth"
import { authActions, authSelectors } from "@/store/slices/auth"
import { EventBus } from "@/utils/event-bus"
import debounce from "lodash/debounce"
import BackgroundAnimation from "./_components/background-animation"
import { useTranslation } from "react-i18next";
import { useCustomMediaQuery } from "@/hooks"

const Layout = (props) => {
  const {
    children,
    className
  } = props || {}
  
  const refreshDepartmentRef = useRef()
  const refreshJobTitleRef = useRef()
  const tabIntervalRef = useRef()

  const dispatch = useDispatch()
  const location = useLocation()
  const { t } = useTranslation();

  const { isMobile } = useCustomMediaQuery()

  const allDepartment = useSelector((state) => state.app.allDepartmentList)
  const allJobTitle = useSelector((state) => state.app.allJobTitleList)

  const isLoggedIn = useSelector(authSelectors.selectIsLoggedIn)

  const [showModalBackdrop, setShowModalBackdrop] = useState(false);
  
  const { 
    getAllDepartment,
    getAllJobTitle,
  } = useUtilityApi()

  const { getUserProfile } = useAuthApi()

  useEffect(() => {
    let isActive = false

    // console.log("setup tabIntervalRef")

    tabIntervalRef.current = setInterval(() => {
      if (!document.hidden) {
        if (isActive === false) {
          isActive = true
          // console.log("tab is active")
          dispatch(appActions.setIsCurrentTabActive(true))
        }
      } else {
        if (isActive === true) {
          isActive = false
          // console.log("is not active")
          dispatch(appActions.setIsCurrentTabActive(false))
        }
      }
    }, 1000)

    return () => {
      // console.log("clean up tabIntervalRef")
      clearTimeout(tabIntervalRef.current)
      tabIntervalRef.current = null
    }
  }, [])

  useEffect(() => {
    if (!isLoggedIn) return

    populateDepartment()
    populateJobTitle()

    refreshDepartmentRef.current = debounce(() => {
      populateDepartment(true)
    }, 1000)

    refreshJobTitleRef.current = debounce(() => {
      populateJobTitle(true)
    }, 1000)
    
    EventBus.$on("refreshDepartment", refreshDepartmentRef.current)
    EventBus.$on("refreshJobTitle", refreshJobTitleRef.current)

    getUserProfile().then((res) => {
      if (res.status !== "ok") return
      dispatch(authActions.setProfileObj(res.result))

      const langCode = res.result.preferredLanguage?.code;
      if (!!langCode) {
        dispatch(appActions.setLocale(langCode))
      }
    })

    return () => {
      EventBus.$off("refreshDepartment", refreshDepartmentRef.current)
      EventBus.$off("refreshJobTitle", refreshJobTitleRef.current)
    }

  }, [isLoggedIn])

  useEffect(() => {
    function removeElementInChildren(obj) {
      obj?.children?.forEach((child) => {
        delete child.element
        removeElementInChildren(child)
      })
    }

    let currentRouteObj = null
    
    allRoutes.forEach((el) => {
      let path = "/"+el.path
      let returnedObj = loopToGetRouteObj(path, el, location.pathname)
      if (!!returnedObj) {
        currentRouteObj = returnedObj
      }
    })
    
    delete currentRouteObj?.element
    removeElementInChildren(currentRouteObj)

    dispatch(appActions.setCurrentRouteObj(currentRouteObj))
  
  }, [location.pathname])

  // make backdrop unscrollable when modal opens
  // can be made into custom hook
  useEffect(() => {
    if (showModalBackdrop) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showModalBackdrop]);

  const loopToGetRouteObj = (fullPath, el, currPath) => {

    if (fullPath === currPath) {
      return el
    }

    let lastPathPortion = el.path
    let isMatched = false

    if (lastPathPortion.includes(":")) {
      // dynamic path, remove :portion in both route and real currPath
      let currPathNameWithoutLast = currPath
      currPathNameWithoutLast = currPathNameWithoutLast.split("/")
      currPathNameWithoutLast.pop()
      currPathNameWithoutLast = currPathNameWithoutLast.join("/")

      let fullPathWithoutLast = fullPath
      fullPathWithoutLast = fullPathWithoutLast.split("/")
      fullPathWithoutLast.pop()
      fullPathWithoutLast = fullPathWithoutLast.join("/")

      isMatched = fullPathWithoutLast === currPathNameWithoutLast

    }

    if (isMatched) {
      return el
    }

    let currentRouteObj = null

    if (!!el.children) {
      el.children.forEach((el2) => {
        let newFullPath = fullPath+"/"+el2.path

        let returnedObj = loopToGetRouteObj(newFullPath, el2, currPath)
        if (!!returnedObj) {
          currentRouteObj = returnedObj
        }
      })
    }

    return currentRouteObj
  }

  const populateDepartment = (shouldRefresh) => {
    if (allDepartment.length > 1 && !shouldRefresh) {
      return // no need fetch again if fetched once or dont need to refresh
    }

    getAllDepartment({
      department: {
        status: "ACTIVE"
      }
    }).then((res) => {
      if (res.status !== "ok") return 

      let list = res.result.list

      const allSelection = {
        id: "all",
        name: t("general.all_department"),
      }

      const unassignedSelection = {
        id: -1,
        name: t("general.unassigned"),
      }

      dispatch(appActions.setAllDepartmentList([allSelection, ...list, unassignedSelection]))
    })
  }

  const populateJobTitle = (shouldRefresh) => {
    if (allJobTitle.length > 1 && !shouldRefresh) {
      return // no need fetch again if fetched once or dont need to refresh
    }
    
    getAllJobTitle({
      job: {
        status: "ACTIVE"
      }
    }).then((res) => {
      if (res.status !== "ok") return

      let list = res.result.list

      const allSelection = {
        id: "all",
        name: "All Job Title",
      }

      const unassignedSelection = {
        id: -1,
        name: "Unassigned",
      }

      dispatch(appActions.setAllJobTitleList([allSelection, ...list, unassignedSelection]))
    })
  }

  return (
    <div 
      id="bo-wrapper"      
      className={`${className} ${isMobile ? 'mobile-view' : ''}`}
    >
      {showModalBackdrop && ( <div className="black-transparent-modal"></div> )}
      <BoSidebar setShowModalBackdrop={setShowModalBackdrop} className={`${showModalBackdrop ? 'sidebar-mobile-expanded' : 'sidebar'}`}/>

      <div id="bo-container">
        <BoHeader />

        <section id="bo-content">
          {children}
        </section>
      </div>

      <BackgroundAnimation />
    </div>
  )
}

const StyledLayout = styled(Layout)`
  background: var(--main-bg);
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  
  #bo-container {
    position: relative;
    z-index: 2;
  }

  #bo-content {
    position: relative;
    width: 100%;
    /* max-width: var(--view-max-width); */
    margin: var(--bo-vertical-spacing) auto;
    height: 100%;
    padding: 0 var(--bo-spacing-right) 0 var(--bo-spacing-left);
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .black-transparent-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    z-index: 10;
  }

  .sidebar-mobile-expanded {
    left: 0;
    height: 100%;
  }

  .sidebar {
    left: var(--sidebar-left);
  }

  // MOBILE VIEW
  @media (max-width: ${(({ theme }) => theme.maxWidth)}) {
    #bo-content {
      padding: 0 var(--mobile-bo-spacing-left-right);
      margin: 0;
    }
  }
`

export default StyledLayout