import { css } from "styled-components"

const FormStyle = css`
  .ant-form-item {
    margin-bottom: 2rem;  
  }

  .ant-form-item-row {
    display: unset;
  }

  .ant-form-item-control-input-content {
    display: flex ;
    flex-direction: column;
  }

  .ant-form-item-label {
    color: #42526E;
    text-align: unset;

    label {
      height: fit-content;
      font: normal normal normal var(--font-size-s) var(--main-font-base);
    }
  }

  .ant-form-item-required {
    font: normal normal normal var(--font-size-s) var(--main-font-base);
  }

  .ant-form-item-explain-error {
    text-align: left;
  }

  .ant-form-item-feedback-icon {
    font-size: 20px;
  }

  // --- INPUT STYLE ---
  .ant-input {
    height: 3.125rem;
    color: var(--default-font-color);
    font: normal normal normal var(--font-size-m) var(--main-font-semi-bold);
    border-radius: 6px !important;

    :hover,
    :focus {
      border-color: var(--form-input-active-color);
    }

    :focus-within {
      box-shadow: unset;
    }
  }

  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused,
  .ant-input:focus,
  .ant-input-focused {
    box-shadow: none;
    border-color: var(--form-input-active-color);
  }

  .ant-input-suffix,
  .ant-input-prefix {
    font-size: 24px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:active,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset !important;
  }
  
  // --- INPUT-NUMBER-STYLE ---
  .ant-input-number {
    height: 3.125rem;
    width: auto;

    :focus,
    :hover,
    &-focused {
      box-shadow: unset;
      border-color: var(--form-input-active-color) !important;
    }
    
    input {
      height: 3.125rem;
      color: var(--default-font-color);
      font: normal normal normal var(--font-size-m) var(--main-font-semi-bold);
    }
  }

  // --- ANT-SELECT ---
  .ant-select {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    height: 3.125rem;
    box-shadow: unset !important;

    :hover,
    &.ant-select-open {
      border-color: var(--form-input-active-color) !important;
      transition: all 0.3s;
    }

    &-selector {
      box-shadow: unset !important;
      background-color: var(--primary-text-color) !important;
      border-radius: 10px !important;
    }

    &-selection-item{
      color: var(--default-font-color);
      font: normal normal normal var(--font-size-m) var(--main-font-semi-bold);
    }

    &-status-error {
      border-color: #ff4d4f;

      .ant-select-selector {
        border: none !important;
      }
    }
  }

  // --- ANT-DATE-PICKER ---
  .ant-picker {
    height: 3.125rem;
    box-shadow: unset !important;

    &.ant-picker-focused.ant-picker,
    &:focus,
    &:hover {
      border-color: var(--form-input-active-color) !important;
    }

    input {
      color: var(--default-font-color);
      font: normal normal normal var(--font-size-m) var(--main-font-semi-bold);
    }
  }

  // --- PHONE-PREFIX-SELECT ---
  .ant-input-group-wrapper {
    height: 3.125rem;
    
    .ant-input-group-addon {
      background: transparent !important;
      border: none !important;
      vertical-align: middle !important;
      padding: unset;
    }
    
    .ant-select {
      width: auto;
      margin: unset;
    }

    .ant-input.css-dev-only-do-not-override-9o5pa {
      border-radius: 6px !important;
    }

    .prefix-phone-form-item {
      margin-right: 0.75rem;
      width: 6rem;
    }
  }

  // --- ANT-RADIO ---
  .ant-radio-group {
    padding-bottom: 0.2rem;
  }

  .ant-radio-wrapper span {
    font: normal normal normal var(--font-size-s) var(--main-font-base);
  }

  .ant-radio-inner {
    border-color: #BCC1DA;
    background-color: unset;
    width: 16px;
    height: 16px;

    &::after {
      width: 16px;
      height: 16px;
      inset-block-start: 50%;
      inset-inline-start: 50%;
    }
  }

  // Responsive
  @media (max-width: ${({ theme }) => theme.maxWidth}) {
    .ant-form-item {
      margin-bottom: 1rem;
    }

    .ant-form-item-label {
      font: var(--font-size-s) var(--main-font-base) !important;
      padding: 0;
    }

    .ant-form-item-label:not(:has(>.ant-form-item-required)) {
      padding-bottom: 0.4rem;
    }

    .ant-form-item-control-input {
      min-height: 0;
    }

    .ant-form-item-explain-error {
      font: normal normal normal var(--font-size-s) var(--main-font-base);
      
      :last-child {
        padding-bottom: 0.5rem;
      }
    }

    .ant-form-item-feedback-icon {
      font-size: 1.125rem;
    }

    // --- INPUT STYLE ---
    .ant-input {
      min-height: 0 !important;
      font-size: var(--font-size-s);

      &:not(textarea.ant-input) {
        height: 2.625rem;
      }

      &::-webkit-input-placeholder {
        font: var(--font-size-s) var(--main-font-base) !important;
      }
    }

    .ant-input-affix-wrapper {
      display: flex;
      align-items: center;
      min-height: 2.5rem !important;
    }

    .ant-input-prefix {
      font-size: var(--font-size-l);
    }

    .ant-input-suffix {
      font-size: 1.125rem;
    }

    // -- INPUT-NUMBER-STYLE ---
    .ant-input-number {
      display: flex;
      align-items: center;
      height: 2.625rem;

      input {
        height: 2.625rem;
        font-size: var(--font-size-s);
      }
    }

    // --- ANT-SELECT ---
    .ant-select {
      height: 2.625rem;

      &-selection-search,
      &-selection-item,
      .ant-select-selector input {
        font-size: var(--font-size-s);
      }
    }

    // --- ANT-DATE-PICKER ---
    .ant-picker {
      height: 2.625rem;

      input {
        font-size: var(--font-size-s);

        &::placeholder {
          font: var(--font-size-s) var(--main-font-base) !important;
        }
      }
    }

    // --- PHONE-PREFIX-SELECT ---
    .ant-input-group-wrapper {
      height: 2.625rem;

      .prefix-phone-form-item {
        width: 5rem;

        .ant-form-item-control-input {
          display: flex;
          justify-content: flex-start;
        }
      }
    }

    // --- ANT-RADIO ---
    .ant-radio-group {
      padding-bottom: 0.4rem;
    }

    .ant-radio-wrapper span {
      font: var(--font-size-s) var(--main-font-base) !important;
    }
  }
`
export default FormStyle