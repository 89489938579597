import { App, Row, Divider, Avatar, Dropdown, Flex, Spin, Select, Tooltip } from "antd"
import styled from "styled-components"
import SvgIcon from "@/components/svg-icon"
import { useNavigate } from 'react-router-dom'
import { useCustomMediaQuery } from "@/hooks"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import LogOutModal from "@/components/log-out-modal"
import { CHECK_NOTIFICATION_INTERVAL_IN_MS } from "@/constants"
import { useNotificationApi } from "@/api/notification"
import { authActions, authSelectors } from "@/store/slices/auth"
import { useCompanyApi } from "@/api/company"
import { appActions } from "@/store/slices/app"
import { useAuthApi } from "@/api/auth"
import { LANGUAGE_OPTION } from "@/constants"
import { useTranslation } from "react-i18next";
import { getNameFirstTwoInitial } from "@/utils/helpers";

const avatarWidthInPx = 50

const menuTooltipStyle = {
  background: "white",
  color: "var(--default-font-color)",
  borderRadius: "1.2rem",
  padding: "0.25rem 1rem",
  fontSize: "var(--font-size-m)",
}

const BoHeader = (props) => {
  const {
    className
  } = props || {}

  const navigate = useNavigate()

  const { isMobile } = useCustomMediaQuery()

  const {
    checkNotification,
  } = useNotificationApi()

  const { 
    getCompanyProfile 
  } = useCompanyApi()

  const { updatePreferredLang } = useAuthApi()
  
  const baseNode = useRef()
  const notificationLoopRef = useRef()

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  const [haveNotification, setHaveNotification] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [companyProfile, setCompanyProfile] = useState([])

  const userProfile = useSelector((state) => state.auth.profileObj)
  const isLoggedIn = useSelector(authSelectors.selectIsLoggedIn)

  const currentRouteObj = useSelector((state) => state.app.currentRouteObj)
  const isCurrentTabActive = useSelector((state) => state.app.isCurrentTabActive)

  const dispatch = useDispatch()
  const locale = useSelector(state => state.app.locale)

  const { message } = App.useApp();
  const { t } = useTranslation();

  useEffect(() => {
    if (!!isLoggedIn) {
      getHeaderProfile()
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isCurrentTabActive !== true) return
    // console.log("setup notificationLoopRef")

    doCheckNotification()

    notificationLoopRef.current = setInterval(
      doCheckNotification, 
      CHECK_NOTIFICATION_INTERVAL_IN_MS
    )

    return () => {
      // console.log("clean up notificationLoopRef")
      clearInterval(notificationLoopRef.current)
      notificationLoopRef.current = null
    }
  }, [isCurrentTabActive])

  const getHeaderProfile = () => {
    setIsLoading(true)

    getCompanyProfile().then((res) => {
      if (res.status !== "ok") return

      dispatch(authActions.setCompanyTimezone(res.result.defaultTimezone))
      setCompanyProfile(res.result)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  const doCheckNotification = () => {
    // if (!isLoggedIn) return

    checkNotification().then((res) => {
      if (res.status !== "ok") return

      setHaveNotification(res.result)
    })
  }

  const doLogout = () => {
    setIsLogoutModalOpen(true)
  }

  const getDropdownItems = () => {
    return [
      {
        label: (
          <Spin spinning={isLoading}>
            <Flex 
              justify="space-between"
              align="center"
              onClick={() => navigate("/company-profile")}
            >
              <Avatar
                className="company-logo"
                src={companyProfile?.companyProfilePicUrl}
              >
                {getNameFirstTwoInitial(companyProfile?.name)}
              </Avatar>
              <p className="dropdown-label">
                {companyProfile?.name}
              </p>
            </Flex>
          </Spin>
        ),
        key: 'company-profile',
      },
      {
        type: 'divider',
      },
      {
        label: (
          <Flex 
            justify="space-between"
            align="center"
            onClick={doLogout}
          >
            <SvgIcon 
              width="24"
              height="24"
              src="logout"
            />
            <p className="dropdown-label">
              {t("header_sidebar.log_out")}
            </p>
          </Flex>
        ),
        key: 'logout',
      },
    ]
  }

  const getPageTitleAndHeaderActionRow = () => (
    <>
      {!!currentRouteObj?.headerProps?.backUrl && (
        <SvgIcon
          src="back-arrow-icon"
          width="22"
          height="22"
          className="back-button"
          onClick={() => navigate(currentRouteObj?.headerProps?.backUrl)}
        />
      )}

      <h1 className="page-title">{t(currentRouteObj?.title)}</h1>

      <div id="header-action-row"/>
    </>
  )

  const getTopMenu = () => (
    <Row 
      className="top-menu shadow" 
      type="flex"
      align="middle"
      justify="center"
    >
      <SvgIcon
        src={
          haveNotification ? "notification-bing" : "notification"
        }
        width="25"
        height="29"
        color="var(--icon-active)"
        className={
          "center-in-div pointer notification-icon"
          + (haveNotification ? " have-notification bounce" : "")
        }
        onClick={() => navigate("/notification")}
      />
      <Divider type="vertical"/>

      <Select
        value={locale}
        bordered={false}
        suffixIcon={null}
        dropdownAlign={{ offset: [0, 75] }}
        dropdownStyle={{ width: 60 }}
        onChange={handleLanguageChange}
        options={LANGUAGE_OPTION.map((el) => ({
          value: el.value,
          label: t(el.label),
        }))}
        trigger={['click']}
        align={{ offset: [10, 20] }}
        placement="bottom"
        popupClassName="bo-header-select-dropdown"
      />
      
      <Divider type="vertical"/>

      <Dropdown
        menu={{
          items: getDropdownItems()
        }}
        trigger={['click']}
        getPopupContainer={() => baseNode.current}
        align={{ offset: [10, 20] }}
        placement="bottomRight"
      >
        <div
          className="avatar-wrapper pointer"
        >
          <Tooltip 
            title={userProfile.name}
            arrow={false}
            overlayInnerStyle={menuTooltipStyle}
            placement="bottomRight"
          >
            <Avatar 
              className="avatar"
              size={avatarWidthInPx}
              src={userProfile.profilePicUrl}
            >
              {getNameFirstTwoInitial(userProfile?.name)}
            </Avatar>
          </Tooltip>
          <SvgIcon
            src="arrow-bottom"
            width="24"
            height="24"
            color="var(--icon-active)"
            className="center-in-div arrow-bottom"
          />
        </div>
      </Dropdown>
    </Row>
  )

  const handleLanguageChange = (value) => {
    dispatch(appActions.setLocale(value))
    
    const payload = { 
      preferredLanguage: {
        code: value
      }
    }

    setIsLoading(true)
    updatePreferredLang(payload).then(res => {
      if (res.status !== "ok") return

      message.open({
        type: "success",
        content: t("message.lang_changed")
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <Row 
      id="bo-header" 
      className={`${className} ${isMobile ? " mobile-view" : ""}`}
      justify="space-between"
      align="middle"
      ref={baseNode}
    > 
      {/* DESKTOP VIEW */}
      { !isMobile && 
        <>
          {getPageTitleAndHeaderActionRow()}

          {getTopMenu()}
        </>
      }

      {/* MOBILE VIEW */}
      { isMobile && 
        <>
          {getTopMenu()}

          <Row className="bottom-menu">
            {getPageTitleAndHeaderActionRow()}
          </Row>
        </>
      }

      <LogOutModal
        open={isLogoutModalOpen}
        onCancel={() => setIsLogoutModalOpen(false)}
      />
    </Row>
  )
}

const StyledComponent = styled(BoHeader)`
  --bo-header-top-menu-height: 38px;

  height: var(--bo-header-height);
  border-bottom: 1px solid #e3e7ee;
  padding: 20px var(--bo-spacing-right) 20px var(--bo-spacing-left);
  background-color: var(--secondary-color);

  .page-title {
    font-size: var(--font-size-xl);
    margin-bottom: 0;
    font-family: var(--main-font-bold);
  }

  .back-button {
    margin-right: 0.25rem;
    padding: 0.25rem;

    &, 
    * {
      cursor: pointer;
    }
  }

  .top-menu {
    padding-left: 15px;
    padding-right: 10px;
    height: var(--bo-header-top-menu-height);
    background: white 0% 0% no-repeat padding-box;
    border-radius: 19px;
    .el-col.border-right {
      height: var(--bo-header-top-menu-height);
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      &.unread {
        svg {
          top: -2px;
          position: relative;
        }
      }
    }

    .notification-icon {
      &.have-notification {
        margin-bottom: 5px;
      }
    }

    .avatar-wrapper {
      position: relative;
      .avatar {
        position: absolute;
        left: 0;
        top: ${-avatarWidthInPx / 4 + "px"};
        height: ${avatarWidthInPx + "px"};
      }
    }
    
    .arrow-bottom {
      margin-left: ${avatarWidthInPx + "px"};
    }

    svg {
      margin: auto 3px;
    }
  }
  .ant-divider {
    height: 1.5rem;
  }

  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }

  .ant-flex {
    height: 100%;
    padding-left: 1rem;
  }

  .dropdown-label {
    font-family: var(--main-font-semi-bold);
    color: #8F99A8;
    flex-basis: 75%;
  }

  .ant-dropdown {
    width: 12.5rem;
  }

  .ant-dropdown-menu-item,
  .custom-date-picker {
    height: 3rem;
  }

  .ant-dropdown-menu-item {
    padding: 0 !important;
  }

  .ant-dropdown-menu-title-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .company-logo {
    border: 1px solid var(--secondary-text-color);
  }

  #header-action-row {
    flex-grow: 2;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .bounce {
    animation: bounce 2s;
  }

  &.mobile-view {
    border-bottom: unset;
    padding: var(--mobile-bo-spacing-left-right);
    height: 100%;

    .top-menu {
      margin: 0 0 1.5rem auto;
    }

    .bottom-menu {
      width: 100%;

      .page-title {
        font-size: 1.1rem;
        margin-left: 0.6rem;
      }

      #header-action-row {
        padding-left: unset;

        .ant-flex {
          padding-left: 0.5rem;
        }

        .ant-select-selector {
          padding: 1.3rem 0.8rem !important;
        }
      }

      .ant-select-selection-item {
        margin-right: 5px;
      }
    }
  }

  @keyframes bounce {
    0%,
    25%,
    50%,
    75%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-12px);
    }
  }
`

export const useHeaderActionRowEl = () => {
  const [headerActionRowEl, setHeaderActionRowEl] = useState(null)

  useEffect(() => {
    setHeaderActionRowEl(document.getElementById('header-action-row'))
  }, [])

  return {
    headerActionRowEl
  }
}


export default StyledComponent